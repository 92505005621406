import React from "react"

import Layout from "../components/Layout"
import MainSection from "../components/MainSection"
import SEO from "../components/Seo"

export default class IndexPage extends React.Component {
  public render() {
    return (
      <Layout>
        <div>
          <SEO title="Home" />
          <MainSection></MainSection>
        </div>
      </Layout>
    )
  }
}
