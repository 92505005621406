import React from "react"
import { Link } from "gatsby"
import { Button, Typography, Carousel, Row, Col, Card, Rate } from "antd"
import styled from "styled-components"
import StrikedText from "./StrikedText"

const mainImage = require("../images/main_image.jpg");
const secondaryImage = require("../images/bekim_iballi.jpg");
const userIcon = require("../images/user_icon.png");
const vehicleIcon = require("../images/vehicle_icon.png");
const shieldIcon = require("../images/shield_icon.png");

const { Title, Paragraph, Text } = Typography
export default class MainSection extends React.Component {
  carousel: any = React.createRef();

  public render() {
    return (
      <SectionsWrapper>
        <section className="main-section">
          <div className="wrapper">
            <div className="head-text">
              <span>
                <StrikedText text="Genießen" textColor="#000" strikeColor="#ffc927" fontWeight={700} type=""/> Sie Ihren {" "}
                <StrikedText text="Umzug" textColor="#000" strikeColor="#ffc927" fontWeight={700} type=""/>. <br /> Wir machen den Rest.
              </span>
            </div>
            <div className="secondary-text">
              <span>
                Fordern Sie Ihr Umzugsangebot kostenlos <br /> und unverbindlich
                in nur 3 Minuten an.
              </span>
            </div>
            <div className="button-wrapper">
              <Button type="primary" size="large">
                <Link to="/form/">Kostenloses Angebot erhalten</Link>
              </Button>
            </div>
          </div>
        </section>
        <section className="alternate-section">
          <div className="wrapper">
            <div className="title-wrapper-round">
              <Title level={3}> Unser Service</Title>
            </div>
            <div className="content-wrapper-after-round">
              <Paragraph className="section-text">
                Mit easypak können Sie sich entspannt zurücklehnen und den Umzug
                Profis überlassen. <br className="hide-sm"/> Neben einem günstigen Preis bekommen Sie
                folgende Leistungen immer inklusive:
              </Paragraph>
              <Row gutter={[16, 16]} className="cards-wrapper">
                <Col span={8} xs={24} sm={24} md={8}>
                  <Card bordered={false}>
                    <div className="card-icon"><img src={userIcon} alt="Image not found"/></div>
                    <div className="card-text">
                      <Title level={4}>Persönliche Beratung</Title>
                    </div>
                  </Card>
                </Col>
                <Col span={8} xs={24} sm={24} md={8}>
                <Card bordered={false}>
                    <div className="card-icon"><img src={vehicleIcon} alt="Image not found"/></div>
                    <div className="card-text">
                      <Title level={4}>Beladung & Transport</Title>
                    </div>
                  </Card>
                </Col>
                <Col span={8} xs={24} sm={24} md={8}>
                <Card bordered={false}>
                    <div className="card-icon"><img src={shieldIcon} alt="Image not found"/></div>
                    <div className="card-text">
                      <Title level={4}>Premium-Versicherung</Title>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Title level={3} id="scroll-link-1"> Zusätzliche Umzugsleistungen</Title>
              <Paragraph className="section-text">
                Passen Sie Ihren Umzug an Ihre Bedürfnisse und Ihr Budget an. Wir bieten folgende <br className="hide-sm"/> Leistungen bei jedem Umzug optional an:
              </Paragraph>
              <div className="tags-wrapper">
                <span className="tag">  Ein- und Auspacken</span><span className="tag">Möbelab- und Aufbau</span><span className="tag">Halteverbotszone einrichten</span><br className="hide-sm"/>
                <span className="tag"> Transport schwerer Gegenstände (z. B. ein Klavier)</span><span className="tag">Verpackungsmaterial</span>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="wrapper">
            <Title level={3}>Firmenumzug (Büro)</Title>
            <Paragraph className="section-text">
              Sie wollen mit Ihrer Firma (Büro) umziehen und suchen ein verlässlichen und fairen Partner? <br/>
              Dann fragen Sie uns einfach ganz bequem per E-Mail <Text strong underline> <a className="highlighted-text" href="mailto:info@easypak-umzug.de"> info@easypak-umzug.de</a> </Text> 
              oder Telefon unter <Text strong> <a className="highlighted-text" href="tel:07141 9163600"> 07141 9163600</a> </Text> an.
            </Paragraph>
          </div>
        </section>
        <section className="alternate-section">
          <div className="wrapper">
            <Title level={3}>Entrümpelung</Title>
            <Paragraph className="section-text">
            Sie müssen eine Wohnung, ein Haus, eine Garage oder eine Lagerhalle entrümpeln?<br/>
            Kein Problem, rufen Sie uns einfach an unter <Text strong><a className="highlighted-text" href="tel:07141 9163600"> 07141 9163600</a></Text> oder schreiben Sie uns eine <br className="hide-sm"/>
            E-Mail an <Text strong underline> <a className="highlighted-text" href="mailto:info@easypak-umzug.de"> info@easypak-umzug.de</a></Text> und wir erstellen Ihnen ein individuelles Angebot.
            </Paragraph>
          </div>
        </section>
        <section className="show-sm">
          <div className="wrapper">
            <div className="mobile-text-wrapper">
              <Title level={3}>Bekim Iballi</Title>
              <Paragraph className="section-text">
                Geschäftsführer
              </Paragraph>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="wrapper">
            <div className="hide-sm">
              <Title level={3}>Bekim Iballi</Title>
              <Paragraph className="section-text">
                Geschäftsführer
              </Paragraph>
            </div>
          </div>
        </section>
        <section>
          <div className="wrapper">
            <Title level={3}>Das sagen unsere Kunden</Title>
            <Row>
              <Col span={2} className="arrow-wrapper" onClick={()=>{this.carousel.prev()}}>
                {"<"}
              </Col>
              <Col span={20}>
                <Carousel autoplay dots={true} className="review-carousal" dotPosition={"bottom"} ref={node => { this.carousel = node; }}>
                  <div>
                    <div className="vertical-space-10"></div>
                    <div>A. Muster</div>
                    <Rate disabled defaultValue={4} />
                    <div className="vertical-space-10"></div>
                    <Title level={4}>Gutes Preis- Leistungsverhältnis!</Title>
                    <div className="vertical-space-10"></div>
                    <Paragraph className="section-text">
                      “Alles lief super, die Umzugshelfer waren sehr nett und pünktlich. Meine Sachen wurden <br className="hide-sm"/> 
                      sicher in die neue Wohnung transportiert. Jederzeit wieder.”
                    </Paragraph>
                    <div className="vertical-space-10"> <br/></div>
                  </div>
                  <div>
                    <div className="vertical-space-10"></div>
                    <div>Miriam Köhler</div>
                    <Rate disabled defaultValue={5} />
                    <div className="vertical-space-10"></div>
                    <Title level={4}>Vorsichtige aber saubere Arbeitsweise</Title>
                    <div className="vertical-space-10"></div>
                    <Paragraph className="section-text">
                      “Die Beratung vorab am Telefon lief reibungslos und Ohne Missverständnisse ab. Preis und Leistung waren hier mehr als fair.
                      Sehr freundliche Männer, vorsichtige aber saubere Arbeitsweise, meine Möbel sind alle heil angekommen. Gerne wieder! Ich 
                      werde euch jeden Fall in meinem Freundeskreis weiterempfehlen.”
                    </Paragraph>
                    <div className="vertical-space-10"><br/></div>
                  </div>
                  <div>
                    <div className="vertical-space-10"></div>
                    <div>Luise Pfeiffer</div>
                    <Rate disabled defaultValue={5} />
                    <div className="vertical-space-10"></div>
                    <Title level={4}>Attraktivsten Preis</Title>
                    <div className="vertical-space-10"></div>
                    <Paragraph className="section-text">
                      “Easypak hat mir bei meinem Umzug den attraktivsten Preis von 6 verglichenen Unternehmen geboten. Es lief alles reibungslos 
                      ab und war es definitiv Wert, bevor man alles selbst verpackt und auspackt. Selbst die Besorgung und Entsorgung der Kartons 
                      war im Preis enthalten. Vielen Dank an das ganze Team!”
                    </Paragraph>
                    <div className="vertical-space-10"><br/></div>
                  </div>
                </Carousel>
              </Col>
              <Col span={2} className="arrow-wrapper" onClick={()=>{this.carousel.next()}}>
                {">"}
              </Col>
            </Row>
          </div>
        </section>
      </SectionsWrapper>
    )
  }
}


const SectionsWrapper = styled.div`
@media (min-width: 1500px) {
  font-size: calc(16px * 1.4)!important;
}
.main-section{
  background-image: url(${mainImage});
  background-size: 110%;
  @media (max-width: 1500px) {
    background-size: 163%;
    background-position: 60% 57%;
  }
  padding-top: 12%;
  padding-bottom: 5%;
  text-align: center;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  font-weight: 600;
  color: #000000;
  width: 100vw;
  min-height: 45vw;
}

.second-section {
  background-image: url(${secondaryImage});
  background-size: 100%;
  padding-top: 15%;
  padding-bottom: 5%;
  text-align: center;
  color: #000000;
  width: 100vw;
  min-height: 45vw;
  background-repeat: no-repeat;
}

.alternate-section{
  background-color: rgb(243, 245, 252);
}

section {
  text-align: center;
}

.wrapper {
  position: relative;
}

.title-wrapper-round {
  width: 300px;
  height: 300px;
  background-color: #f3f5fc;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -60px;
  margin-left: -150px;
  padding-top: 40px;

  @media (min-width: 1500px) {
    width: calc(300px * 1.4);
    height: calc(300px * 1.4);
    margin-left: calc(-300px * 1.4 / 2);
    top: calc(-300px * 1.4 / 5);
  }
}
.content-wrapper-after-round {
  position: relative;
  padding-top: 10px;
}
.section-text {
  .highlighted-text {
    color: #000000;
    &:hover {
      color: #ffc927;
    }
  }
}

.head-text {
  font-size: 60px;
  font-weight: 700;
  @media (min-width: 1500px) {
    font-size: calc(60px * 1.4)!important;
  }
  line-height: 100%;
  margin-bottom: 60px;
}

.cards-wrapper {
  margin-bottom: 30px!important;

  .card-icon {
    margin: 24px auto 18px auto;

    img {
      height: 72px;
    }
  }
  .card-text {
    margin-bottom: 32px;
  }
}

.secondary-text{
  margin-bottom: 70px;
}

.button-wrapper{
  margin-bottom: 90px;
}
.tags-wrapper{
  margin-bottom: 12px;
  color: #000000;
}
.tags-wrapper .tag{
  display: inline-block;
  height: 35px;
  background-color: #fff;
  line-height: 35px;
  @media (min-width: 1500px) {
    height: calc(35px * 1.4);
    line-height: calc(35px * 1.4);
  }
  padding: 0 12px;
  margin-right: 10px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px!important;
  font-weight: 600;
  overflow: hidden;
}

#scroll-link-1{
  scroll-margin-top: 90px;
}
.ant-carousel{
  font-size: inherit!important;
}

.show-sm {
  display: none;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  cursor: pointer;
  :hover{
    color: #ffc927;
  }
}

@media (max-width: 480px){
    .hide-sm {
      display: none;
    }
    .show-sm {
      display: block;
    }
    .main-section {
      padding-top: 0;
      background-size: 250%;
      background-position: 30% -10%;
      padding-bottom: 210px;
    }
    .head-text {
      font-size: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .secondary-text {
      margin-bottom: 20px;
      line-height: 120%;
    }
    .section-text {
      line-height: 145%
    }
    .second-section {
      min-height: 55vw;
    }
    .mobile-text-wrapper{
      .ant-typography {
        margin-bottom: 0!important;
      }
    }
  }
`